import React from 'react'

// import Layout from '@components/Layout'
import Layout from '../components/Layout'
import Image from '../components/Image'
import { IEvent } from '../types/index'
import { t } from '../components/Translator'
import { no_events_planned } from '../constants'
// @ts-ignore
import content from '../../content/events.yaml'

const styles = {
    noEvents: {
        padding: '40px 10px 40px 10px',
        width: '100%',
        margin: 'auto',
        textAlign: 'center'
    }
}

const Event: React.FC<IEvent> = ({ event }) => {

    // @ts-ignore
    const { weekday, day, month, hour, abstract } = event

    return (
        <div className="event-row">
            <div className="event-date">
                <span className="weekday">{t(weekday)}</span>
                <span className="day">{day}</span>
                <span className="month">{t(month)}</span>
            </div>
            <div className="event-time-slots">
                <ul className="list">
                    {/*todo: nested loop for events on he same date, 1-n li's*/}
                    <li className="event-time-slot">
                        <span className="time">{t(hour)}</span>
                        <h5 className="abstract">{t(abstract)}</h5>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const EventsPage: React.FC = () => {

    const images = content.images
    const title = content.title
    const events = content.events
    const info = content.info

    // todo : original (late 2020) 'Vestige' html website design see:
    //  D:\ARCHIVE_projects\SSK\20210218_ssk-html-site\ssk-evenementen.html
    //  if address ever required: it contains link fa fa-map-marker

    return (
        <Layout head={content.head} banner={content.banner}>
            <div className="row">
                {/*todo: check for no-events*/}
                <div className="col-lg-6">
                    <div className="contact-badge">
                        <div style={styles.noEvents}><h3>{t(no_events_planned)}</h3></div>
                    </div>
                </div>
                {/*<div className="col-lg-6">*/}
                {/*    <div className="spacer-10"/>*/}
                {/*    <h2 dangerouslySetInnerHTML={{ __html: t(title) }}/>*/}
                {/*    <h2 style={{color:'#000000'}}>{t(info.reservation)}</h2>*/}
                {/*    <div className="contact-badge">*/}
                {/*        <h2>{info.tickets}</h2>*/}
                {/*        <div style={{marginTop:'-10px'}}>*/}
                {/*            <span>*/}
                {/*                /!*<i className="fa fa-link"/>*!/*/}
                {/*                <a href={`https://${info.website}`} target="_blank">{info.website}</a>*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*        <div style={{marginTop:'5px'}}>*/}
                {/*            <span><i className="fa fa-phone"/>{info.phone}</span>*/}
                {/*        </div>*/}
                {/*        <div style={{marginTop:'5px'}}>*/}
                {/*            <span><i className="fa fa-envelope"/><a href={`mailto:${info.email}`}>{info.email}</a></span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="spacer-30"/>*/}
                {/*    <div className="events">*/}
                {/*        {events.map((event: any, index: number) =>*/}
                {/*            <Event key={index} event={event}/>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-12">
                            <Image image={images[0]}/>
                        </div>
                    </div>
                    <div className="spacer-30"/>
                    <div className="row">
                        <div className="col-6">
                            <Image image={images[1]}/>
                        </div>
                        <div className="col-6">
                            <Image image={images[2]}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default EventsPage